import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const defaultOpenAiState = () => {
    return {
        page: 0,
        isDisplayAiChatNavbar: false,
        isDisplayOverlayLoading: false,
        isReSearch: false,
        isStock: false,
        search_word: '',
        kana_readings: [],
        other_readings: '',
        search_results: [],
        search_results_genre: [],
        search_results_title: [],
        selectedCategories: [],
        selectedSortCode: 1,
        selectedPublishDate: [0, -1],
        selectedTopPopularity: -1,
        selectedSellPrices: [0, -1],
        selectedDiscountRate: [0, -1],
        selectedBookAge: [0, -1],
        isDateNotFound: false,
        isSearchError: false,
        isComplete: false,
        isLoading: false,
        isPriceDisplay: true,
        isPublishDateDisplay: true,
        count: null,
        tags: null,
        tagSearchDisplaySize: null,
        vectorSearch: {
            results: [],
            count: 0,
            page: 0,
            isComplete: false,
            isLoading: false,
            isDataNotFound: false,
            isSearchError: false,
        },
        numberOfDuplicates: 0,
        notSearchTagCategory: true,
        userMessageInputValue: null,
        isExternal: false,
        searchKeywordDescription: "",
        relatedKeywordsOfSearchKeyword: [],
        isLoadingDescriptionAndRelatedKeywords: false
    }
}

function updateFavoriteFlag(items) {
    const vsCatalogIDs = items.map((item) => item.vs_catalog_id);
    if (0 < vsCatalogIDs.length) {
        return axios.post('/api/library/folder/favorite/items/intersection', {
            vs_catalog_id: vsCatalogIDs,
        }).then(({data: favoriteVsCatalogIDs}) => {
            //favoriteVsCatalogIDsと一致するitemsのisFavoriteをtrueにする
            const favoriteSet = new Set(favoriteVsCatalogIDs);
            items.forEach(item => {
                Vue.set(item, 'isFavorite', favoriteSet.has(item.vs_catalog_id));
            });
        }).catch(e => {
            if (e.response.status !== 403) {
                console.error(e);
            }
        });
    }
}

const moduleOpenAi = {
    namespaced: true,
    state: defaultOpenAiState(),
    getters: {
        getSearchResults(state) {
            // 重複する書誌を除いて返却
            const resultOfRemoved = state.search_results.filter(function (x, i, items) {
                // vs_catalog_id がnullの場合、またはコミックセットの場合はチェックしない（子コミックセットを考慮）
                if (!x.vs_catalog_id || x.vs_catalog_id.startsWith("VC")) {
                    return x
                }
                return items.findIndex(function (y) {
                    return y.vs_catalog_id === x.vs_catalog_id;
                }) === i
            });

            // 重複した書誌の数をセット
            state.numberOfDuplicates = state.search_results.length - resultOfRemoved.length;

            return resultOfRemoved;
        },
        getSearchResultsCount(state) {
            return state.search_results.length;
        },
        getVectorSearchResults(state) {
            return state.vectorSearch.results;
        },
        getVectorSearchResultsCount(state) {
            return state.vectorSearch.results[0] ? state.vectorSearch.results[0].length : 0;
        },
        getIsPublishDateDisplay(state) {
            return state.isPublishDateDisplay;
        },
        getSelectedSellPricePayload(state) {
            let selectedSellPrices = [];
            state.selectedSellPrices.forEach((item, key) => {
                selectedSellPrices[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
            });
            return (!selectedSellPrices[0] && !selectedSellPrices[1]) ? null : selectedSellPrices;
        },
        getSelectedDiscountRatePayload(state) {
            let selectedDiscountRate = [];
            state.selectedDiscountRate.forEach((item, key) => {
                selectedDiscountRate[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
            });
            return (!selectedDiscountRate[0] && !selectedDiscountRate[1]) ? null : selectedDiscountRate;
        },
        getSelectedPublishDatePayload(state) {
            let selectedPublishDate = [];
            state.selectedPublishDate.forEach((item, key) => {
                selectedPublishDate[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
            });
            return (!selectedPublishDate[0] && !selectedPublishDate[1]) ? '' : selectedPublishDate.reverse();
        },
        getSelectedBookAgePayload(state) {
            let selectedBookAge = [];
            state.selectedBookAge.forEach((item, key) => {
                selectedBookAge[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
            });
            return (!selectedBookAge[0] && !selectedBookAge[1]) ? '' : selectedBookAge.reverse();
        },
        getIsComplete(state) {
            return state.isComplete;
        },
        getIsDisplayAiChatNavbar(state) {
            return state.isDisplayAiChatNavbar;
        },
        getVectorSearchIsDataNotFound(state) {
            return state.vectorSearch.isDataNotFound;
        },
        getVectorSearchIsSearchError(state) {
            return state.vectorSearch.isSearchError;
        },
        getUserMessageInputValue(state) {
            return state.userMessageInputValue;
        },
        getIsExternal(state) {
            return state.isExternal;
        },
        getSearchKeywordDescription(state) {
            return state.searchKeywordDescription
        },
        getRelatedKeywordsOfSearchKeyword(state) {
            return state.relatedKeywordsOfSearchKeyword;
        },
        getIsLoadingDescriptionAndRelatedKeywords(state) {
            return state.isLoadingDescriptionAndRelatedKeywords;
        }
    },
    actions: {
        async research({state, dispatch, commit}) {
            dispatch("resetSearchState");
            await dispatch('searchItem');
            await dispatch('vectorSearchItems');
        },
        async karteSearch({state, dispatch, commit}) {
            dispatch("resetSearchState");
            await dispatch('karteSearchItems');
        },
        resetSearchState({state, dispatch, commit}) {
            commit('mutateIsReSearch', true);
            commit('resetPage');
            commit('resetSearchResults');
            commit('resetVectorSearchState');
            commit('mutateIsDateNotFound', false);
            commit('mutateIsSearchError', false);
            commit('mutateIsComplete', false);
            commit('mutateIsDisplayOverlayLoading', true);
        },
        searchItem({state, dispatch, commit, getters}) {
            if (state.isComplete) {
                return;
            }

            commit('incrementPage');
            commit('mutateIsLoading', true);
            let optionalDisplaySize = null;
            optionalDisplaySize = VueConfig.search.SEARCH_ITEMS_PER_PAGE;
            const maxItemNumber = optionalDisplaySize ? optionalDisplaySize : VueConfig.search.SEARCH_ITEMS_PER_PAGE;

            return axios.get('/api/search', {
                params: {
                    page: state.page,
                    search_word: state.search_word,
                    kana_readings: state.kana_readings,
                    other_readings: state.other_readings,
                    conditions_stock: state.isStock ? '2' : '1',
                    conditions_sorting: state.selectedSortCode,
                    ...(state.selectedCategories.length ? {conditions_category: state.selectedCategories} : ''),
                    ...(
                        getters.getIsPriceDisplay ?
                            getters.getSelectedSellPricePayload ? {conditions_price: getters.getSelectedSellPricePayload} : '' :
                            getters.getSelectedDiscountRatePayload ? {conditions_rate: getters.getSelectedDiscountRatePayload} : ''),
                    ...(
                        getters.getIsPublishDateDisplay ?
                            getters.getSelectedPublishDatePayload ? {conditions_age: getters.getSelectedPublishDatePayload} : '' :
                            getters.getSelectedBookAgePayload ? {conditions_age: getters.getSelectedBookAgePayload} : ''
                    ),
                    ...(state.selectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE ? {conditions_popularGoods: state.selectedTopPopularity} : ''),
                    tags: state.tags,
                    display_size: optionalDisplaySize,
                    notSearchTagCategory: state.notSearchTagCategory,
                    ...(state.isExternal ? {isExternalSearch: state.isExternal} : ''),
                },
            })
                .then(({data}) => {
                    if (!data) {
                        return;
                    }

                    commit('applySearchResults', data.items);
                    commit('mutateIsDisplayOverlayLoading', false);
                    if (Number.isFinite(data.count)) {
                        commit('mutateCount', data.count);
                    }

                    //追加の検索結果がない場合
                    if (data.items.length === 0 || data.items.length < maxItemNumber) {
                        commit('mutateIsComplete', true);
                    }

                    //検索結果が全くない
                    if (getters.getSearchResultsCount === 0) {
                        commit('mutateIsDateNotFound', true);
                    }
                })
                .catch((error) => {
                    commit('mutateIsSearchError', true);
                    commit('mutateIsLoading', false);
                }).finally(() => {
                    commit('mutateIsLoading', false);
                });
        },
        vectorSearchItems({state, commit, getters}, payload) {
            if (state.vectorSearch.isComplete) {
                return;
            }
            const api = "/api/vectorSearch";
            const maxItemNumber = VueConfig.search.SEARCH_ITEMS_PER_PAGE;

            state.vectorSearch.page === 1 && commit('mutateVectorSearchPage', 2);
            commit('incrementVectorSearchPage');
            commit('mutateIsVectorSearchLoading', true);

            return axios
                .get(api, {
                    params: {
                        page: state.vectorSearch.page,
                        search_word: state.search_word,
                        kana_readings: state.kana_readings,
                        other_readings: state.other_readings,
                        conditions_stock: state.isStock ? '2' : '1',
                        conditions_sorting: state.selectedSortCode,
                        ...(state.selectedCategories.length ? {conditions_category: state.selectedCategories} : ''),
                        ...(
                            getters.getIsPriceDisplay ?
                                getters.getSelectedSellPricePayload ? {conditions_price: getters.getSelectedSellPricePayload} : '' :
                                getters.getSelectedDiscountRatePayload ? {conditions_rate: getters.getSelectedDiscountRatePayload} : ''),
                        ...(
                            getters.getIsPublishDateDisplay ?
                                getters.getSelectedPublishDatePayload ? {conditions_age: getters.getSelectedPublishDatePayload} : '' :
                                getters.getSelectedBookAgePayload ? {conditions_age: getters.getSelectedBookAgePayload} : ''
                        ),
                        ...(state.selectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE ? {conditions_popularGoods: state.selectedTopPopularity} : ''),
                        ...(state.tags ? {tags: state.tags} : ''),
                        ...(payload && payload.a ? {a: payload.a} : ''),
                        ...(payload && payload.b ? {b: payload.b} : ''),
                        ...(payload && payload.img_weight ? {img_weight: payload.img_weight} : ''),
                        display_size: maxItemNumber,
                        ...(state.isExternal ? {isExternalSearch: state.isExternal} : ''),
                    },
                })
                .then(({data}) => {
                    if (!data) {
                        return;
                    }

                    if (state.vectorSearch.page <= 2) {
                        commit('applyVectorSearchResults', [data.items]);
                    } else {
                        commit('applySearchResults', data.items);
                        commit('applyVectorSearchResults', [data.items]); // 検証用に配列で持たせている
                    }

                    if (Number.isFinite(data.count)) {
                        commit('mutateVectorSearchCount', data.count);
                    }

                    //追加の検索結果がない場合
                    if (data.items.length === 0 || data.items.length < maxItemNumber) {
                        commit('mutateIsVectorSearchComplete', true);
                    }

                    //検索結果が全くない
                    if (getters.getVectorSearchResultsCount === 0) {
                        commit('mutateIsVectorDataNotFound', true);
                    }
                    commit('mutateIsVectorSearchLoading', false);
                })
                .catch((error) => {
                    commit('mutateIsVectorSearchError', true);
                    commit('mutateIsVectorSearchLoading', false);
                });
        },
        karteSearchItems({state, commit, getters}) {
            const api = "/api/karteSearch";
            return axios
                .get(api, {
                    params: {
                        search_word: state.search_word
                    },
                })
                .then(({data}) => {
                    if (!data) {
                        return;
                    }
                    updateFavoriteFlag(data.items);
                    commit('applySearchResults', data.items)
                    if (Number.isFinite(data.count)) {
                        commit('mutateVectorSearchCount', data.count);
                    }
                    //検索結果が全くない
                    if (getters.getVectorSearchResultsCount === 0) {
                        commit('mutateIsVectorDataNotFound', true);
                    }
                    commit('mutateIsVectorSearchLoading', false);
                })
                .catch((error) => {
                    commit('mutateIsVectorSearchError', true);
                    commit('mutateIsVectorSearchLoading', false);
                });
        },
        changeStockCondition({dispatch, commit}, payload) {
            commit('mutateIsStock', payload);
            dispatch('research');
        },
        getDescriptionAndRelatedKeywordsOfSearchWords({state, commit}, items) {
            if (!state.search_word || state.search_word.length > 20) {
                return;
            }
            commit("mutateIsLoadingDescriptionAndRelatedKeywords", true);

            // 必要なフィールドのみを抜き出す関数
            const selectFields = (arr) => arr.map(item => ({
                title: item.title,
                authors: item.authors,
                publisher: item.publisher,
                briefSummary: item.briefSummary,
                keywords: item.keywords
            }));

            // シャッフル関数
            const shuffleArray = (arr) =>
                arr
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);

            const existsAIFieldItems = shuffleArray(selectFields(items.filter(item => item.briefSummary || item.keywords?.length)));
            let randomItems = [];
            // AIに渡す最大書籍数
            const maxProvidesCount = 20;
            if(existsAIFieldItems.length >= maxProvidesCount ){
                randomItems  = existsAIFieldItems.slice(0, maxProvidesCount);
            }else{
                // AI系フィールドを含む書籍が20冊未満の場合は、含まない書籍も入れる。
                const otherItems = shuffleArray(selectFields(items.filter(item => !item.briefSummary && !item.keywords?.length)));
                randomItems  = [...existsAIFieldItems, ...otherItems].slice(0, maxProvidesCount);
            }

            const api = "/api/search/searchRelatedKeyWordsWithDescription"
            return axios
                .post(api, {
                    search_word: state.search_word,
                    search_results: randomItems,
                    result_count: items.length
                })
                .then(({data}) => {
                    if (!data) {
                        return;
                    }
                    const description = data.description;
                    const relatedKeywords = data.relatedKeywords;
                    if (description) {
                        commit("mutateSearchKeywordDescription", description);
                    }
                    if (relatedKeywords && relatedKeywords.length > 0) {
                        commit("mutateRelatedKeywordsOfSearchKeyword", relatedKeywords);
                    }
                })
                .catch((error) => {

                }).finally(() => {
                    commit("mutateIsLoadingDescriptionAndRelatedKeywords", false);
                });
        },

// ランダムに指定件数を選択するヘルパー関数
        shuffleAndSelect(array, count) {
            const shuffled = array
                .map(value => ({value, sort: Math.random()}))
                .sort((a, b) => a.sort - b.sort)
                .map(({value}) => value);

            return shuffled.slice(0, count);
        }
    },
    mutations: {
        mutateIsDisplayAiChatNavbar(state, value) {
            state.isDisplayAiChatNavbar = value;
        },
        mutateIsReSearch(state, value) {
            state.isReSearch = value;
        },
        mutateIsStock(state, value) {
            state.isStock = value;
        },
        incrementPage(state) {
            state.page++;
        },
        incrementVectorSearchPage(state) {
            state.vectorSearch.page++;
        },
        mutatePage(state, value) {
            state.page = value;
        },
        mutateVectorSearchPage(state, value) {
            state.vectorSearch.page = value;
        },
        mutateSearchWord(state, value) {
            state.search_word = value;
        },
        mutateKanaReadings(state, value) {
            state.kana_readings = value;
        },
        mutateOtherReadings(state, value) {
            state.other_readings = value;
        },
        applySearchResults(state, value) {
            state.search_results.push(...value);
        },
        applySearchResultsGenre(state, value) {
            state.search_results_genre.push(value);
        },
        applySearchResultsTitle(state, value) {
            state.search_results_title.push(...value);
        },
        applyVectorSearchResults(state, value) {
            state.vectorSearch.results.push(...value);
        },
        mutateIsDateNotFound(state, value) {
            state.isDateNotFound = value;
        },
        mutateIsVectorDataNotFound(state, value) {
            state.vectorSearch.isDataNotFound = value;
        },
        mutateIsSearchError(state, value) {
            state.isSearchError = value;
        },
        mutateIsVectorSearchError(state, value) {
            state.vectorSearch.isSearchError = value;
        },
        mutateIsComplete(state, value) {
            state.isComplete = value;
        },
        mutateIsVectorSearchComplete(state, value) {
            state.vectorSearch.isComplete = value;
        },
        mutateIsLoading(state, value) {
            state.isLoading = value;
        },
        mutateIsVectorSearchLoading(state, value) {
            state.vectorSearch.isLoading = value;
        },
        mutateSelectedCategories(state, value) {
            state.selectedCategories = value;
        },
        mutateSelectedSellPrices(state, value) {
            state.selectedSellPrices = value;
        },
        mutateSelectedDiscountRate(state, value) {
            state.selectedDiscountRate = value;
        },
        mutateSelectedPublishDate(state, value) {
            state.selectedPublishDate = value;
        },
        mutateSelectedBookAge(state, value) {
            state.selectedBookAge = value;
        },
        mutateSelectedTopPopularity(state, value) {
            state.selectedTopPopularity = value;
        },
        mutateSelectedSortCode(state, value) {
            state.selectedSortCode = value;
        },
        resetSearchResults(state) {
            state.search_results = [];
        },
        resetPage(state) {
            state.page = 0;
        },
        // vuetifyのresetでは任意の値に初期化が出来ないためstateをコピーして初期化
        resetState(state) {
            let defaultState = defaultOpenAiState();
            delete defaultState.search_word;
            delete defaultState.search_results;
            delete defaultState.search_results_genre;
            delete defaultState.page;
            delete defaultState.kana_readings;
            delete defaultState.other_readings;
            Object.assign(state, defaultState)
        },
        // べクトル検索のstateを初期化
        resetVectorSearchState(state) {
            state.vectorSearch = {
                results: [],
                count: 0,
                page: 0,
                isComplete: false,
                isLoading: false,
                isDataNotFound: false,
                isSearchError: false,
            };
        },
        mutateIsDisplayOverlayLoading(state, value) {
            state.isDisplayOverlayLoading = value;
        },
        mutateCount(state, value) {
            state.count = value;
        },
        mutateVectorSearchCount(state, value) {
            state.vectorSearch.count = value;
        },
        mutateTags(state, payload) {
            state.tags = payload;
        },
        mutateTagSearchDisplaySize(state, value) {
            state.tagSearchDisplaySize = value;
        },
        mutateNotSearchTagCategory(state, value) {
            state.notSearchTagCategory = value;
        },
        setUserMessageInputValue(state, value) {
            state.userMessageInputValue = value;
        },
        setIsExternal(state, value) {
            state.isExternal = value;
        },
        mutateSearchKeywordDescription(state, value) {
            state.searchKeywordDescription = value;
        },
        mutateRelatedKeywordsOfSearchKeyword(state, value) {
            state.relatedKeywordsOfSearchKeyword = value;
        },
        mutateIsLoadingDescriptionAndRelatedKeywords(state, value) {
            state.isLoadingDescriptionAndRelatedKeywords = value;
        }
    },
}

export {moduleOpenAi};